import React from 'react';

import menuconfig from './menuconfig.js';

import { makeStyles } from '@material-ui/core/styles';
import AuthCloser from '../Components/AuthCloser.js';
import Container from '@material-ui/core/Container';

import {
  Link
} from "react-router-dom";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Menu1 from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    backgroundColor: "#88bfc8",
  },
  menu: {
    backgroundColor: "#88bfc8",
  },
  list: {
    backgroundColor: "#88bfc8",
    display: 'flex',
    flexDirection: 'row',
    padding: 0
  },
  listitemtext: {
    textAlign: "center",
    backgroundColor: "#88bfc8",
    minWidth: 100
  }
}))

function MenuNode({ item, isChild }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  if (item.children) {

    const handleAnchorClose = () => {
      setOpen(!open);
      setAnchorEl(null);
    };
    const handleClick = (event) => {
      setOpen(!open);
      if (!open) {
        setAnchorEl(event.currentTarget);
      }
    };


    const textkey = "textlabel_" + item.id;
    const divchildkey = "divchild_" + item.id;


    

    return (<><ListItem key={divchildkey} button onClick={handleClick}>
      <ListItemText key={textkey} className={classes.listitemtext} primary={item.id} color="inherit" /></ListItem>
      <Menu1
        classes={{ paper: classes.menu }}
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        elevation={0}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={handleAnchorClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      > {item.children.map((itemc, index) => {
        return (<MenuItem className={classes.menu} component={Link} onClick={handleAnchorClose} to={itemc.path} key={index} >{itemc.id}</MenuItem>);
      })}
      </Menu1>
      </>);
  } else {
    let className = "";
    if (isChild) {
      className = classes.nested;
    }

    const b = () => (<ListItem className={className} key={item.id} button component={Link} to={item.path}><ListItemText className={classes.listitemtext} primary={item.id} color="inherit" /></ListItem>);
    const key = "menu_" + item.id;
    if (item.onlyroute !== true) {
      if (item.privat) {
        return (<AuthCloser auth_node={b} key={key}></AuthCloser>);
      } else {
        return b();
      }
    }
  }
}

export default function Menu() {
  const classes = useStyles();

  return (
    <Container maxWidth="lg">
      <List id="toolbar_menu" className={classes.list} color="inherit">
        {menuconfig.map((item, index) => {
          if (item.onlyroute !== true && item.showOnBigPage === true) {
            return (<MenuNode item={item} isChild={false} key={index}></MenuNode>);
          }
          return (<div key={index}></div>);
        })}
      </List>
    </Container>
  );
}

/*
        {menuconfig.map((item, index) => {
          if(item.children) {

          } else {
            const b = ( <ListItem key={item.id} button component={Link} to={item.path}><ListItemText className={classes.listitemtext} primary={item.id} color="inherit"  /></ListItem>);
            const key = "menu_" + index;
            if(item.onlyroute !== true) {
              if(item.privat) {
                return (<AuthCloser auth_node={b} key={key}></AuthCloser>);
              } else {
                return b;
              }
            }
          }
        })}
*/