import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import { makeStyles } from '@material-ui/core/styles';

import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import SecurityIcon from '@material-ui/icons/Security';
import InfoIcon from '@material-ui/icons/Info';
import ScheduleIcon from '@material-ui/icons/Schedule';
import CallIcon from '@material-ui/icons/Call';
import HelpIcon from '@material-ui/icons/Help';

import {
    Link
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    stickToBottom: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
        backgroundColor: "b",
        [theme.breakpoints.down('xs')]: {
            //backgroundColor: "#88bfc8",
            display: "none",
        }
    },
    stickToBottom2: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
        backgroundColor: "b",
        [theme.breakpoints.up('md')]: {
            //backgroundColor: "#88bfc8",
            display: "none",
        }
    }
}));

export default function ButtonNav() {
    const classes = useStyles();

    return (
        <>
            <BottomNavigation showLabels className={classes.stickToBottom}>
                <BottomNavigationAction label="Anfrage" component={Link} to="/contactform" icon={<QuestionAnswerIcon />} />
                <BottomNavigationAction label="Anruf" component={Link} to={{ pathname: "tel:+4929419599518" }} target="_blank" icon={<CallIcon />} />
                <BottomNavigationAction label="Termin" component={Link} to="/appointment" icon={<ScheduleIcon />} />
                <BottomNavigationAction label="FAQ" component={Link} to="/faq" icon={<HelpIcon />} />
                <BottomNavigationAction label="Impressum" component={Link} to="/impressum" icon={<InfoIcon />} />
                <BottomNavigationAction label="Datenschutz" component={Link} to="/datenschutz" icon={<SecurityIcon />} />
            </BottomNavigation>
            <BottomNavigation showLabels className={classes.stickToBottom2}>
                <BottomNavigationAction label="Anfrage" component={Link} to="/contactform" icon={<QuestionAnswerIcon />} />
                <BottomNavigationAction label="Anruf" component={Link} to={{ pathname: "tel:+4929419599518" }} target="_blank" icon={<CallIcon />} />
                <BottomNavigationAction label="Termin" component={Link} to="/appointment" icon={<ScheduleIcon />} />
                <BottomNavigationAction label="Impressum" component={Link} to="/impressum" icon={<InfoIcon />} />
            </BottomNavigation>
        </>);
}

