import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import "firebase/analytics";
import { useEffect } from 'react';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBtn-WVz5zfEEWFzsgdzAJajsFCXFwtYRs",
  authDomain: "oceanservicegroup-6bc11.firebaseapp.com",
  projectId: "oceanservicegroup-6bc11",
  storageBucket: "oceanservicegroup-6bc11.appspot.com",
  messagingSenderId: "979187832662",
  appId: "1:979187832662:web:4514cc332e4c36f82068f7",
  measurementId: "G-B962NKLXZX"
};

export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const firebaseAppAuth = firebaseApp.auth();

export const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
  facebookProvider: new firebase.auth.FacebookAuthProvider(),
  signInWithEmailAndPasswordProvider: new firebase.auth.EmailAuthProvider(),
};

export const db = firebase.firestore();
export const Firebase = firebase;
export const analytics = firebase.analytics();
analytics.logEvent(analytics, 'page_load');

export default firebaseConfig;