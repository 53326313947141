import React from 'react';

const  menuconfig = [
    { 'id': "OceanServiceGroup",'path': "/", Component: React.lazy(() => import( "../Pages/home")), 'privat': false, showOnBigPage: true  },
    { 'id': "Über uns", 'privat': false, showOnBigPage: true, children: [
        { 'id': "Team", 'path': "/aboutus" , Component: React.lazy(() => import( "../Pages/aboutus")), 'privat': false , showOnBigPage: true },
        { 'id': "Karriere", 'path': "/jobstart" , Component: React.lazy(() => import( "../Pages/jobstart")), 'privat': false, showOnBigPage: true  }
    ]},
    { 'id': "Produkte", 'privat': false, showOnBigPage: true, children: [
        { 'id': "OceanDoors",'path': "/garagentore" , Component: React.lazy(() => import( "../Pages/garagentore")), 'privat': false, showOnBigPage: true  },
        { 'id': "OceanGarden",'path': "/ocean_garden" , Component: React.lazy(() => import( "../Pages/ocean_garden")), 'privat': false, showOnBigPage: true  },
        { 'id': "OceanBioClean",'path': "/ocean_bioclean", Component: React.lazy(() => import( "../Pages/ocean_bioclean")), 'privat': false, showOnBigPage: true   },
        { 'id': "Service",'path': "/servicewartung" , Component: React.lazy(() => import( "../Pages/servicewartung")), 'privat': false, showOnBigPage: true  },
        { 'id': "Referenzen",'path': "/references" , Component: React.lazy(() => import( "../Pages/referenzen")), 'privat': false, showOnBigPage: true  }
    ]  },
    //{ 'id': "Garagenausstattung",'path': "/garagezubehor", Component: React.lazy(() => import( "../Pages/garagezubehor")), 'privat': false   },
    { 'id': "Shop",'path': "/shop", Component: React.lazy(() => import( "../Pages/shop")) , 'privat': false, showOnBigPage: true },
    { 'id': "Blog",'path': "/blog", Component: React.lazy(() => import( "../Pages/blog")) , 'privat': false, showOnBigPage: true },
    
    { 'id': "Meine Garage", 'path': "/euregarage" , Component: React.lazy(() => import( "../Pages/euregarage")), 'privat': true, showOnBigPage: true  },
    { 'id': "FAQ", 'path': "/faq" , Component: React.lazy(() => import( "../Pages/faq")), 'privat': false, onlyroute: false, showOnBigPage: false },
    { 'id': "Datenschutz", 'path': "/datenschutz" , Component: React.lazy(() => import( "../Pages/datenschutz")), 'privat': false, onlyroute: false, showOnBigPage: false },

    
    { 'id': "Bewerbungsprozess", 'path': "/job_bewerbungsprozess" , Component: React.lazy(() => import( "../Pages/job_bewerbungsprozess")), 'privat': false, onlyroute: true   },
    { 'id': "Willkommen", 'path': "/job_willkommen" , Component: React.lazy(() => import( "../Pages/job_willkommen")), 'privat': false, onlyroute: true   },
    { 'id': "Karriere", 'path': "/jobstart" , Component: React.lazy(() => import( "../Pages/job_jobs")), 'privat': false, onlyroute: true   },
    { 'id': "dasmoegenwir", 'path': "/job_dasmoegenwir" , Component: React.lazy(() => import( "../Pages/job_dasmoegenwir")), 'privat': false, onlyroute: true   },

    
    
    { 'id': "mapsearch", 'path': "/mapsearch" , Component: React.lazy(() => import( "../Pages/mapsearch")), 'privat': false, onlyroute: true  },
    { 'id': "evaluation", 'path': "/evaluation" , Component: React.lazy(() => import( "../Pages/evaluation")), 'privat': false, onlyroute: true  },
    { 'id': "evaluation", 'path': "/evaluation/:id" , Component: React.lazy(() => import( "../Pages/evaluation")), 'privat': false, onlyroute: true  },
    { 'id': "evaluationlist", 'path': "/evaluationlist" , Component: React.lazy(() => import( "../Pages/evaluationlist")), 'privat': false, onlyroute: true  },
    { 'id': "contactform", 'path': "/contactform" , Component: React.lazy(() => import( "../Pages/contactform")), 'privat': false, onlyroute: true  },
    { 'id': "testpage", 'path': "/testpage" , Component: React.lazy(() => import( "../Pages/testpage")), 'privat': false, onlyroute: true  },
    { 'id': "impressum", 'path': "/impressum" , Component: React.lazy(() => import( "../Pages/impressum")), 'privat': false, onlyroute: true  },
    
    { 'id': "appointment", 'path': "/appointment" , Component: React.lazy(() => import( "../Pages/appointment")), 'privat': false, onlyroute: true  },

    { 'id': "blogedit", 'path': "/blogedit/:id" , Component: React.lazy(() => import( "../Pages/blogedit")), 'privat': true, onlyroute: true  },
    { 'id': "blogedit2", 'path': "/blogedit" , Component: React.lazy(() => import( "../Pages/blogedit")), 'privat': true, onlyroute: true  },
    { 'id': "blogeditlist", 'path': "/blogeditlist" , Component: React.lazy(() => import( "../Pages/blogeditlist")), 'privat': true, onlyroute: true  },
    { 'id': "blog", 'path': "/blog" , Component: React.lazy(() => import( "../Pages/blog")), 'privat': false, onlyroute: true  },
    { 'id': "blog2", 'path': "/blog/:id" , Component: React.lazy(() => import( "../Pages/blog")), 'privat': false, onlyroute: true  },
    { 'id': "signin", 'path': "/signin" , Component: React.lazy(() => import( "../Pages/signin")), 'privat': false, onlyroute: true  }
    
    
];

export default menuconfig;

/*
    { 'id': "OCEAN Garden",'path': "/ocean_garden" , Component: React.lazy(() => import( "../Pages/ocean_garden")), 'privat': false  },
    { 'id': "OCEAN Bioclean",'path': "/ocean_bioclean", Component: React.lazy(() => import( "../Pages/ocean_bioclean")), 'privat': false   },

*/