import React from 'react';
import Button from '@material-ui/core/Button';

import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import Menu from '../Menu/menu';

import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import Logo_1 from '../oceandoor_complete.jpeg';
import Logo_small from '../oceandoor.jpeg';

import AuthCloser from '../Components/AuthCloser';
import Container from '@material-ui/core/Container';

import {
  Link
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {

  },
  toolbar: {
    backgroundColor: "#88bfc8"
  },
  title: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    color: "black"
  },
  appBar: {
    marginTop: theme.spacing(0),
    [theme.breakpoints.up('sm')]: {
      //backgroundColor: "#88bfc8",
      backgroundColor: "white",
    },

    [theme.breakpoints.up('md')]: {

    }
  },
  toolBar: {

  },

  button: {

    margin: theme.spacing(1),
    color: "black",
    [theme.breakpoints.down("md")]: {
      minWidth: 32,
      paddingLeft: 8,
      paddingRight: 8,
      "& .MuiButton-startIcon": {
        margin: 0
      }
    }
  },
  buttonText: {
    color: "black",
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  },
  logo: {
    height: '50px'
  },
}))

export default function MyAppBar(props) {

  const handleDrawerOpen = props.handleDrawerOpen;
  const classes = useStyles();

  return (
    <div className={classes.root}>

      <AppBar position="fixed" className={classes.appBar}>
        <Container maxWidth="lg">
          <Toolbar className={classes.toolBar}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerOpen}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Hidden xsDown implementation="css">
              <Link to="/">
                <img src={Logo_1} className={classes.logo} alt="Logo" />
              </Link>
            </Hidden>
            <Hidden smUp implementation="css">
              <Link to="/">
                <img src={Logo_small} className={classes.logo} alt="Logo" />
              </Link>
            </Hidden>
            <Typography variant="h6" className={classes.title}></Typography>

            <Button
              edge="end"
              className={classes.button}
              startIcon={<FacebookIcon />}
              component={Link} to={{ pathname: "https://www.facebook.com/OceanServiceGroup/" }} target="_blank"
            >
              <span className={classes.buttonText}>Facebook</span>
            </Button>
            <Button
              edge="end"
              className={classes.button}
              startIcon={<LinkedInIcon />}
              component={Link} to={{ pathname: "https://www.linkedin.com/company/oceanservicegroup/" }} target="_blank"
            >
              <span className={classes.buttonText}>LinkedIn</span>
            </Button>
            <Button
              edge="end"
              className={classes.button}
              startIcon={<InstagramIcon />}
              component={Link} to={{ pathname: "https://www.instagram.com/oceanservicegroup/" }} target="_blank"
            >
              <span className={classes.buttonText}>Instagram</span>
            </Button>
            <AuthCloser auth_node={(user, logout, login) => {
              return (<Button className={classes.button} onClick={logout}>Logout {user ? user.nam : ""}</Button>);
            }} unauth_node={(user, logout, login) => {
              return (<Button className={classes.button} component={Link} to="/signin">Login</Button>);
            }}></AuthCloser>
          </Toolbar>
        </Container>
        <Hidden smDown implementation="css">
          <Menu />
        </Hidden>
      </AppBar>

    </div>
  );
}

/*

*/


