import React from 'react';
import menuconfig from './menuconfig.js';

import AuthCloser from '../Components/AuthCloser.js';

import {
  Link
} from "react-router-dom";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    backgroundColor: "#88bfc8",
  },
  list: {
    backgroundColor: "#88bfc8",
  },
  listitemtext: {
    textAlign: "center"
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}))

function MenuNode({ item, handleDrawerClose }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  if (item.children) {
    const handleClick = () => {
      setOpen(!open);
    };

    const keylist = "list_" + item.id;
    const collapsekey = "collapse_" + item.id;
    const textkey = "textlabel_" + item.id;
    const divchildkey = "divchild_" + item.id;

    return (<div key={divchildkey}><ListItem key={item.id} button onClick={handleClick}>
      <ListItemText key={textkey} className={classes.listitemtext} primary={item.id} color="inherit" />{open ? <ExpandLess /> : <ExpandMore />}</ListItem>
      <Collapse key={collapsekey} in={open} timeout="auto" unmountOnExit>
        <List key={keylist} component="div" className={classes.list} disablePadding>
          {item.children.map((itemc) => {
            const keynode = "menunode_" + itemc.id;
            return (<MenuNode item={itemc} handleDrawerClose={handleDrawerClose} key={keynode} />);
          })}
        </List>
      </Collapse>
      <Divider />
    </div>);
  } else {
    const b = () => (<ListItem className={classes.nested} key={item.id} button component={Link} to={item.path} onClick={handleDrawerClose}><ListItemText className={classes.listitemtext} primary={item.id} color="inherit" onClick={handleDrawerClose} /></ListItem>);
    const key = "menu_" + item.id;
    if (item.onlyroute !== true) {
      if (item.privat) {
        return (<AuthCloser auth_node={b} key={key}></AuthCloser>);
      } else {
        return b();
      }
    }
  }
}

export default function Drawermenu(props) {
  const classes = useStyles();
  const handleDrawerClose = props.handledrawerclose;

  return (
    <List key="toolbar_menu" component="nav" className={classes.list} color="inherit" >
      {menuconfig.map((item, index) => {
        if (item.onlyroute !== true) {
          return (<MenuNode item={item} handleDrawerClose={handleDrawerClose} key={item.id}></MenuNode>);
        }
        return ("");
      })}
    </List>
  );
}
