import React, { Suspense }  from 'react';

import {
    Switch,
    Route
  } from "react-router-dom";

import menuconfig from '../Menu/menuconfig.js';


export default function RouterOcean() {
    const fallback = <div> Loading... </div>;

    return (            
        <Suspense fallback={fallback}>
            <Switch>
                {menuconfig.map(({path, Component, children}, i) => { 
                if(children) {
                    const childs = children.map(({path, Component, children}, i) => {
                        return <Route exact key={i} path={path} component={() => {
                            return <Component />
                            }} />
                    });
                    return childs;
                }
                
                return <Route exact key={i} path={path} component={() => {
                return <Component />
                }} />
            }
                )}
                <Route path='/shop_url' component={() => { 
                    window.location.href = 'https://www.ocean24.shop/'; 
                    return null;
                }}/>                
            </Switch>
        </Suspense>);
}