import React from 'react';
import withFirebaseAuth from 'react-with-firebase-auth';
import { firebaseAppAuth, providers } from '../config';

function AuthCloser(props) {
    if(props === undefined) props = {};

    const {
      user,
      signOut,
      signInWithGoogle,
      signInWithFacebook,
      signInWithEmailAndPassword
    } = props;

    const auth = props.auth_node ? props.auth_node(user, signOut, () => {  }) : (<p></p>);
    const unauth = props.unauth_node ? props.unauth_node(user, signOut, signInWithGoogle) : ("");

    return (user ? auth : unauth);
}

export default withFirebaseAuth({
  providers,
  firebaseAppAuth,
})(AuthCloser);