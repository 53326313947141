import React from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';

import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles, ThemeProvider, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';
import MyAppBar from './Menu/Appbar';

/* Navigation */
import Drawermenu from './Menu/drawermenu.js';

import ElevationScroll from './Components/ElevationScroll';
import ButtonNav from './Menu/ButtonMenu';

import { CookieBanner } from '@palmabit/react-cookie-law';

import {
  BrowserRouter as Router
} from "react-router-dom";


import RouterOcean from './Components/Router';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginBottom: 50,
  },

  drawer: {
    [theme.breakpoints.up('xs')]: {
      flexShrink: 0,
    },
  },
  // necessary for content to be below app bar
  toolbar: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('xs')]: {
      minHeight: 136,
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: 64,
    }
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
    backgroundColor: "#88bfc8",
    color: "white"
  },
  content: {
    flexGrow: 1,
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
}));

function ResponsiveDrawer(props) {
  const { window } = props;
  const classes = useStyles();
  let theme = createMuiTheme({
    palette: {
      primary: {
        main: "#88bfc8",
      },
      secondary: {
        main: "#88bfc8",
      },
    }, typography: {
      fontFamily: [
        "Barlow Condensed", "sans-serif"
      ].join(','),
      fontSize: 15,
      body2: {
        fontSize: "1rem"
      }
    },
  });
  theme = responsiveFontSizes(theme);

  const [state, setState] = React.useState(false);

  const handleDrawerOpen = () => {
    setState(true);
  };
  const handleDrawerClose = () => {
    setState(false);
  };

  const drawer = (
    <div className={classes.drawerContainer}>
      <div />
      <Drawermenu handledrawerclose={handleDrawerClose} />
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;




  return (


    <Router>
      <ThemeProvider theme={theme}>
        <Container maxWidth="lg">
          <div className={classes.root}>

            <CssBaseline />

            <ElevationScroll {...props}>
              <MyAppBar handleDrawerOpen={handleDrawerOpen}></MyAppBar>
            </ElevationScroll>
            <nav className={classes.drawer} aria-label="mailbox folders">
              {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
              <Hidden smUp implementation="css">
                <Drawer
                  container={container}
                  variant="temporary"
                  anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                  open={state} onClose={handleDrawerClose}
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                  }}
                >
                  {drawer}
                </Drawer>
              </Hidden>
            </nav>

            <main className={classes.content}>
              <div className={classes.toolbar} />
              <RouterOcean></RouterOcean>
            </main>
          </div>
        </Container>
        <ButtonNav />
        <CookieBanner
          message="Diese Internetseite verwendet Cookies und Google Analytics für die Analyse und Statistik. Wir nutzen Cookies zu unterschiedlichen Zwecken, unter anderem zur Analyse und für personalisierte Marketing-Mitteilungen. Durch die weitere Nutzung der Website stimmen Sie der Verwendung zu."
          wholeDomain={true}
          policyLink="/datenschutz"
          privacyPolicyLinkText="Datenschutzvereinbarung"
          onAccept={() => { }}
          acceptButtonText="akzeptieren"
          necessaryOptionText="notwendig"
          managePreferencesButtonText="weitere Informationen"
          savePreferencesButtonText="speichern"
          showPreferencesOption={false}
          showStatisticsOption={false}
          showMarketingOption={false}
        />
      </ThemeProvider>
    </Router>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
